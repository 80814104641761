
import { Component, Prop, Vue } from 'vue-property-decorator';
import GDialog from '@/components/gsk-components/GskDialog.vue';
import GButton from '@/components/gsk-components/GskButton.vue';
import { UserModule } from '@/store/modules/user.module';

@Component({
  components: {
    GDialog,
    GButton,
  },
})
export default class IdleDialog extends Vue {
  @Prop({ type: String, default: 'Stay signed in' }) readonly buttonText!: string;
  @Prop({ type: String, default: 'Your session is about to expire' }) readonly headerLabel!: string;

  // Reference: https://jakzaizzat.com/idle-timer-vue/
  // This idle dialog opens once Idle-Vue detects a user has been idle for 28 minutes
  // Idle-Vue settings found in register-globals.ts file

  // Countdown time in ms (2 minutes default after user has been idle for 28 minutes)
  time = 1000 * 60 * 2;

  logout() {
    UserModule.logoutUser();
    window.location.assign('/session-timeout.html');
  }

  created() {
    let timerId = setInterval(() => {
      // popup starts countdown for actual logout unless user stops idling
      this.time -= 1000;

      if (!this.$store.state?.idleVue?.isIdle) clearInterval(timerId);

      if (this.time < 1) {
        // session timed out
        clearInterval(timerId);
        this.logout();
      }
    }, 1000);
  }
}
