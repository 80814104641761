import Vue from 'vue';

import '@gsk-tech/gsk-tooltip/gsk-tooltip';
import '@gsk-tech/gsk-dialog/gsk-dialog';
import '@gsk-tech/gsk-button/gsk-button';
import '@gsk-tech/gsk-card/gsk-card';
import '@gsk-tech/gsk-checkbox/gsk-checkbox';
import '@gsk-tech/gsk-chips/gsk-chip';
import '@gsk-tech/gsk-circular-progress/gsk-circular-progress';
import '@gsk-tech/gsk-drawer/gsk-drawer';
import '@gsk-tech/gsk-fab/gsk-fab';
import '@gsk-tech/gsk-formfield/gsk-formfield';
import '@gsk-tech/gsk-icon/gsk-icon';
import '@gsk-tech/gsk-icon-button/gsk-icon-button';
import '@gsk-tech/gsk-input-chips/gsk-input-chips';
import '@gsk-tech/gsk-linear-progress/gsk-linear-progress';
import '@gsk-tech/gsk-list/gsk-list';
import '@gsk-tech/gsk-menu/gsk-menu';
import '@gsk-tech/gsk-people-picker/gsk-people-picker';
import '@gsk-tech/gsk-radio/gsk-radio';
import '@gsk-tech/gsk-radio/gsk-radio-group';
import '@gsk-tech/gsk-ripple/gsk-ripple';
import '@gsk-tech/gsk-snackbar/gsk-snackbar';
import '@gsk-tech/gsk-switch/gsk-switch';
import '@gsk-tech/gsk-tab-bar/gsk-tab-bar';
import '@gsk-tech/gsk-textfield/gsk-textfield';
import '@gsk-tech/gsk-select/gsk-select';
import '@gsk-tech/gsk-top-app-bar/gsk-top-app-bar';
import 'rapidoc/dist/rapidoc-min';
import '@gsk-tech/gsk-autocomplete/gsk-autocomplete';
import '@gsk-tech/gsk-file-upload/gsk-file-upload';
import '@gsk-tech/gsk-alert';
import '@gsk-tech/gsk-badge';
import '@gsk-tech/gsk-slider';

// import './components/WebComponents/CopyCode/DocumentationCopyCode';
// import './components/WebComponents/ComponentPreviewer/ComponentPreviewer';
// import './components/WebComponents/ComponentPicker/ComponentPicker';
// import './components/WebComponents/TokenDownload/TokenDownload';
// import './components/WebComponents/UserFeedback/UserFeedback';
// import './components/WebComponents/CodeSandbox';

Vue.config.ignoredElements = [/^gsk-/, /^gsk-two-/, 'rapi-doc', /^docs-/];
