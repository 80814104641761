import Vue, { VNode } from 'vue';
import { sync } from 'vuex-router-sync';
import './register-polyfills';
import './register-globals';
import App from './App.vue';
import router from './router';
import store from './store';

sync(store, router);
const appSelector = '#app';
const app = new Vue({
  router,
  store,
  render: (h): VNode => h(App),
});

// eslint-disable-next-line no-console

if (process.env.VUE_APP_CLIENT_API_MOCKS === 'true') {
  // set github and ado dummy tokens
  sessionStorage.setItem('gitHubToken', 'abcdefg');
  sessionStorage.setItem('adoToken', 'abcdefg');
  sessionStorage.setItem('msalToken', 'abcdefg');
}

app.$mount(appSelector);
