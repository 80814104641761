import { ValidationRule } from 'vee-validate/dist/types/types';
import { extend } from 'vee-validate';

/**
 * array validators used for validating form fields with array values
 * the built in validators support array values, but they run against each
 * value in the array rather than the whole array itself, e.g. "max" will
 * look at each value in the array and check if it's longer than "max" when
 * we want to say the array itself should have a max length of n
 */

const arrayMax: ValidationRule = (value, params) => {
  if (Array.isArray(value) && Array.isArray(params)) {
    const max = +params[0];
    return value.length <= max ? '' : `Length should be less than ${max}`;
  }
  throw new TypeError('Only validate arrays with this');
};

extend('arrayMax', arrayMax);

const arrayMin: ValidationRule = (value, params) => {
  if (Array.isArray(value) && Array.isArray(params)) {
    const min = +params[0];
    return value.length >= min ? '' : `Length should be at least ${min}`;
  }
  throw new TypeError('Only validate arrays with this');
};

extend('arrayMin', arrayMin);

const arrayRequired: ValidationRule = value => {
  if (Array.isArray(value)) {
    return {
      required: true,
      valid: value.length > 0,
    };
  }
  throw new TypeError('Only validate arrays with this');
};

extend('arrayRequired', {
  validate: arrayRequired,
  computesRequired: true,
  message: 'This field is required',
});

const boolRequired: ValidationRule = value => {
  return {
    required: true,
    valid: !!value,
  };
};

extend('boolRequired', {
  validate: boolRequired,
  computesRequired: true,
  message: 'This field is required',
});
