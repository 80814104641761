import Vue from 'vue';
import Router, { NavigationGuard, Route } from 'vue-router';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import FullScreenModalLayout from '@/layouts/FullScreenFormLayout.vue';
import { RouteNames } from '@/constants';
import DocumentationLayout from '@/layouts/DocumentationLayout.vue';
import { HELP_ROOT_PATH } from '@/constants/help.constants';
import { FeatureFlagMnemonics } from '@/types/feature-flags.types';
import EmptyLayout from '@/layouts/EmptyLayout.vue';

import AuthCallback from './views/AuthCallback.vue';

if (window.location.pathname !== `/${RouteNames.AuthCallback}`) {
  // FeatureFlagsModule.getFeatureFlags();
}

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  /* eslint-disable @typescript-eslint/explicit-function-return-type */
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { selector: '#layout-main', x: 0, y: 0 };
    }
  },
  routes: [
    {
      path: '/authcallback',
      name: RouteNames.AuthCallback,
      component: AuthCallback,
    },
    {
      path: '/',
      redirect: '/rpa-ops',
    },
    {
      path: '*',
      alias: '/404',
      name: RouteNames.NotFound,
      component: () => import('./views/NotFound.vue'),
    },
    {
      path: '/rpa-ops/open-ticket/:logicalBotId/:botId',
      component: () => import('./layouts/FullScreenFormLayout.vue'),
      // beforeEnter: makeFeatureFlagRouteGuard(FeatureFlagMnemonics.RPAOPS),
      children: [
        {
          path: '/',
          name: RouteNames.RpaOpsOpenTicket,
          component: () => import('./views/OpenTicket.vue'),
        },
        {
          path: ':type(blocking|nonblocking|intermittent)',
          name: RouteNames.RpaOpsBlockingTicket,
          component: () => import('./views/BlockingTicket.vue'),
        },
      ],
    },
    {
      path: '/rpa-ops/sign-off/:logicalBotId',
      component: () => import('./layouts/FullScreenFormLayout.vue'),
      // beforeEnter: makeFeatureFlagRouteGuard(FeatureFlagMnemonics.RPAOPS),
      children: [
        {
          path: '/',
          name: RouteNames.RpaOpsSignOff,
          component: () => import('./views/SignOff.vue'),
        },
      ],
    },
    {
      path: '/rpa-ops/sign-off-history/:logicalBotId',
      component: () => import('./layouts/FullScreenFormLayout.vue'),
      // beforeEnter: makeFeatureFlagRouteGuard(FeatureFlagMnemonics.RPAOPS),
      children: [
        {
          path: '/',
          name: RouteNames.RpaOpsSignOffHistory,
          component: () => import('./views/SignOffHistory.vue'),
        },
      ],
    },
    {
      path: '/rpa-ops/sign-off-details/logical-bot/:logicalBotId/sign-off/:signOffId',
      component: () => import('./layouts/FullScreenFormLayout.vue'),
      // beforeEnter: makeFeatureFlagRouteGuard(FeatureFlagMnemonics.RPAOPS),
      children: [
        {
          path: '/',
          name: RouteNames.RpaOpsSignOffDetails,
          component: () => import('./views/SignOffDetails.vue'),
        },
      ],
    },
    {
      path: '/rpa-ops/reset-data',
      name: RouteNames.RpaOpsResetData,
      component: () => import('./views/ResetData.vue'),
    },
    {
      path: '/rpa-ops/search',
      component: () => import('./layouts/FullScreenFormLayout.vue'),
      // beforeEnter: makeFeatureFlagRouteGuard(FeatureFlagMnemonics.RPAOPS),
      children: [
        {
          path: '/',
          name: RouteNames.RpaOpsSearch,
          component: () => import('./views/Search.vue'),
        },
      ],
    },
    {
      path: '/rpa-ops/edit-bot/:logicalBotId',
      component: () => import('./layouts/FullScreenFormLayout.vue'),
      // beforeEnter: makeFeatureFlagRouteGuard(FeatureFlagMnemonics.RPAOPS),
      children: [
        {
          path: '/',
          name: RouteNames.RpaOpsEditBot,
          component: () => import('./views/EditBot.vue'),
        },
      ],
    },
    {
      path: '/rpa-ops/edit-bot-history/:logicalBotId',
      component: () => import('./layouts/FullScreenFormLayout.vue'),
      // beforeEnter: makeFeatureFlagRouteGuard(FeatureFlagMnemonics.RPAOPS),
      children: [
        {
          path: '/',
          name: RouteNames.RpaOpsEditBotHistory,
          component: () => import('./views/EditBotHistory.vue'),
        },
      ],
    },
    {
      path: '/rpa-ops/unassigned-bots',
      component: () => import('./layouts/SearchLayout.vue'),
      // beforeEnter: makeFeatureFlagRouteGuard(FeatureFlagMnemonics.RPAOPS),
      children: [
        {
          path: '/',
          name: RouteNames.RpaOpsUnassignedBots,
          component: () => import('./views/UnassignedBots.vue'),
        },
      ],
    },
    {
      path: '/rpa-ops/scheduled-reporting',
      component: () => import('./layouts/SearchLayout.vue'),
      // beforeEnter: makeFeatureFlagRouteGuard(FeatureFlagMnemonics.RPAOPS),
      children: [
        {
          path: '/',
          name: RouteNames.RpaOpsScheduledReporting,
          component: () => import('./views/ScheduledReportingList.vue'),
        },
      ],
    },
    {
      path: '/rpa-ops/scheduled-reporting/create',
      component: () => import('./layouts/FullScreenFormLayout.vue'),
      // beforeEnter: makeFeatureFlagRouteGuard(FeatureFlagMnemonics.RPAOPS),
      children: [
        {
          path: '/',
          name: RouteNames.RpaOpsScheduledReportingCreate,
          component: () => import('./views/ScheduledReportingCreate.vue'),
        },
      ],
    },
    {
      path: '/rpa-ops/scheduled-reporting/edit/:reportName',
      component: () => import('./layouts/FullScreenFormLayout.vue'),
      // beforeEnter: makeFeatureFlagRouteGuard(FeatureFlagMnemonics.RPAOPS),
      children: [
        {
          path: '/',
          name: RouteNames.RpaOpsScheduledReportingEdit,
          component: () => import('./views/ScheduledReportingCreate.vue'),
        },
      ],
    },
    {
      path: '/rpa-ops/scheduled-reporting/view/:reportName',
      component: () => import('./layouts/FullScreenFormLayout.vue'),
      // beforeEnter: makeFeatureFlagRouteGuard(FeatureFlagMnemonics.RPAOPS),
      children: [
        {
          path: '/',
          name: RouteNames.RpaOpsScheduledReportingView,
          component: () => import('./views/ScheduledReportingView.vue'),
        },
      ],
    },
    {
      path: '/rpa-ops',
      component: () => import('./layouts/SearchLayout.vue'),
      // beforeEnter: makeFeatureFlagRouteGuard(FeatureFlagMnemonics.RPAOPS),
      children: [
        {
          path: '/',
          name: RouteNames.Om27,
          component: () => import('./views/main.vue'),
        },
      ],
    },
  ],
});


export default router;
