import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import axiosRetry, { IAxiosRetryConfig, exponentialDelay } from 'axios-retry';
import URLs from './urls';

window.localStorage.setItem('refreshingToken', 'false');
export interface AxiosRetryRequestConfig extends AxiosRequestConfig {
  'axios-retry'?: IAxiosRetryConfig;
}

const service = axios.create({
  baseURL: window.location.origin + URLs.Base,
});
axiosRetry(service, { retries: 0, retryDelay: exponentialDelay });

service.interceptors.response.use(
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  function (response): AxiosResponse<any> {
    // Do something with response data
    return response;
  },
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  async function (error): Promise<any> {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<any>(async (resolve, reject) => {
      // Do something with response error
      const originalRequest = error.config;
      if (error.response && error.response.status === 401 && !originalRequest._retry) {
        console.log('Got 401 from server, redirecting to login');
        console.log(error.response.request.responseURL);
        redirectToLogin();
      } else {
        return reject(error);
      }
    });
  },
);

async function redirectToLogin() {
  const provider = process.env.VUE_APP_OAUTH_PROVIDER || 'ping';

  let oauthState = '';
  if (!window.localStorage.getItem('oauth_state')) {
    oauthState = Date.now().toString(36) + Math.random();
    window.localStorage.setItem('oauth_state', oauthState);
  } else {
    oauthState = window.localStorage.getItem('oauth_state') as string;
  }

  let loginLink = `/api/auth/login?`;
  loginLink += `provider=${encodeURIComponent(provider)}`;
  loginLink += `&state=${encodeURIComponent(oauthState)}`;
  loginLink += `&callbackLink=${encodeURIComponent(`${location.origin}/authcallback`)}`;

  window.localStorage.setItem('oauth_provider', provider);
  window.localStorage.setItem('callbackLink', `${location.origin}/authcallback`);

  localStorage.routePathBeforeGoToLogin = location.pathname;

  window.location.href = loginLink;
}

async function sleep(s: number) {
  return new Promise(resolve => setTimeout(resolve, s * 1000));
}

// ensure token refresh is not in progress
async function waitForClear(): Promise<void> {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      while (window.localStorage.getItem('refreshingToken') === 'true') {
        await sleep(1);
      }
      resolve();
    } catch (e) {
      reject(e);
    }
  });
}

/**
 * this will replace url path params that look like ":param" in /url/path/:param
 * with the equivalent key value pair from axios request config.params
 *
 * Given:
 *   url    -- /listings/:listingId/users/:userId
 *   params -- { listingId: 1, userId: 3, otherParam: 'hello' }
 *
 * the resulting url would be:
 *   /listings/1/users/3?otherParam=hello
 */
service.interceptors.request.use(async (config): Promise<AxiosRequestConfig> => {
  let url = config.url || '';
  // ensure token isn't being refreshed - this will work across browser sessions
  await waitForClear();
  if (config.params && url.includes(':')) {
    const params = { ...config.params };
    Object.entries(params as Record<string, string | number>).forEach(([key, val]): void => {
      const midPattern = `(/)(:${key})(/)`;
      const endPattern = `(/):${key}$`;
      if (new RegExp(`${midPattern}|${endPattern}`).test(url)) {
        const encodedValue = encodeURIComponent(String(val));
        const mid = new RegExp(midPattern, 'g');
        url = url.replace(mid, `$1${encodedValue}$3`);
        const end = new RegExp(endPattern, 'g');
        url = url.replace(end, `$1${encodedValue}`);
        delete params[key];
      }
    });
    config.url = url;
    config.params = params;
    config.withCredentials = true;
  }

  return config;
});

/*

service.interceptors.response.use(undefined, err => (err: AxiosError) => {
    let res = err.response;
    if (res!.status === 401 && res!.config  && !res.config.__isRetryRequest) {
      return new Promise((resolve, reject) => {
          success => {
            setTokens(success.access_token, success.refresh_token)
            err.config.__isRetryRequest = true
            err.config.headers.Authorization = 'Bearer ' + getAccessToken()
            resolve(axios(err.config))
          },
          error => {
            reject(error)
            console.log('Refresh login error: ', error)
          }
        )
      })
    }

  }
);

 */

export default service;

export function getData<T = unknown>(res: AxiosResponse<T>): T {
  return res.data;
}
