import timezoneAbbrevations from './timezone_abbrevations.json';

export enum RouteNames {
  Om27 = 'om27',
  NotFound = 'not-found',
  RpaOpsUnassignedBots = 'unassigned-bots',
  RpaOpsScheduledReporting = 'scheduled-reporting',
  RpaOpsScheduledReportingCreate = 'scheduled-reporting-create',
  RpaOpsScheduledReportingEdit = 'scheduled-reporting-edit',
  RpaOpsScheduledReportingView = 'scheduled-reporting-view',
  RpaOpsOpenTicket = 'rpa-open-ticket',
  RpaOpsBlockingTicket = 'rpa-blocking-ticket',
  RpaOpsSignOff = 'rpa-sign-off',
  RpaOpsSignOffHistory = 'rpa-sign-off-history',
  RpaOpsSignOffDetails = 'rpa-sign-off-details',
  RpaOpsResetData = 'rpa-reset-data',
  RpaOpsSearch = 'rpa-search',
  AuthCallback = 'authcallback',
  RpaOpsEditBot = 'rpa-edit-logical-bot',
  RpaOpsEditBotHistory = 'rpa-edit-logical-bot-history',
}

export enum Ping {
  Logout = 'https://federation-qa.gsk.com/idp/startSLO.ping',
}

export enum Events {
  ShowSnackbar = 'show-snackbar',
  OpenPopover = 'open-popover',
  ClosePopover = 'close-popover',
}

export enum Environments {
  Dev = 'Dev',
  Qa = 'QA',
  Prod = 'Prod',
}

export const mocksEnabled = process.env.VUE_APP_CLIENT_API_MOCKS === 'true';
export const kongApiKeyIdDefaultValue = 'apikey';

export const AUTH_SRC: Record<
  string,
  {
    mnemonic: string;
    readableName: string;
    shortName: string;
    apiRegistrationEnum: string;
    servicePropEnumVal: string;
  }
> = {
  AD_OAUTH: {
    mnemonic: 'AD_OAUTH',
    shortName: 'AD',
    readableName: 'Azure Active Directory (OAuth)',
    apiRegistrationEnum: 'AZUREAD_OAUTH2',
    servicePropEnumVal: '2',
  },
  PING_OAUTH: {
    mnemonic: 'PING_OAUTH',
    shortName: 'Ping',
    readableName: 'PingFederate (OAuth)',
    apiRegistrationEnum: 'PING_OAUTH2',
    servicePropEnumVal: '1',
  },
  API_KEY: {
    mnemonic: 'API_KEY',
    shortName: 'Basic',
    readableName: 'API Key',
    apiRegistrationEnum: 'APIKEY',
    servicePropEnumVal: '0',
  },
};

export const TZ_ABBREVATIONS: Record<string, string> = Object.freeze(timezoneAbbrevations);
